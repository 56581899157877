import $ from 'jquery';
// //
//window.$ = $;
//window.jQuery = $;

import 'bootstrap-sass'
import 'bootstrap-sass/assets/javascripts/bootstrap.js'
import './isotope.js'
import './lightgallery.js'

// Import all plugins
import {Alert, Button, Carousel, Collapse, Dropdown, Modal, Popover, ScrollSpy, Tab, Toast, Tooltip} from 'bootstrap-sass'
//console.log(Alert, Button, Carousel, Collapse, Dropdown, Modal, Popover, ScrollSpy, Tab, Toast, Tooltip)

function debounce(func, wait) {
    let timeout;

    return function executedFunction() {
        const context = this;
        const args = arguments;

        const later = function() {
            timeout = null;
            func.apply(context, args);
        };

        if(timeout) {
            //timeout already set
            return;
        }
        timeout = setTimeout(later, wait);
    };
};

$(document).on('scroll', debounce(function() {
    //console.log($(window).scrollTop());
    if($(window).scrollTop() > 0) {
      $('.navbar').addClass('opaque');
    } else {
      $('.navbar').removeClass('opaque');
    }
}, 200));
  

$(document).ready(function() {
    $('.teaser-video').hover(function toggleControls() {
        if (this.hasAttribute("controls")) {
            this.removeAttribute("controls")
        } else {
            this.setAttribute("controls", "controls")
        }
    });
});
  
$(document).ready(function() {
    $('a[href*=\\#]').on('click', function(event){
        event.preventDefault();
        $('html,body').animate({scrollTop:$(this.hash).offset().top - 110}, 500);
    });
});



// Set the date we're counting down to

// get date from a html element
if ( document.getElementById("countdown") ) {
    var date = document.getElementsByClassName("countdown");
    var date = date[0].textContent;

    var countDownDate = new Date(date).getTime();

    // Update the count down every 1 second
    var x = setInterval(function() {

        // Get today's date and time
        var now = new Date().getTime();

        // Find the distance between now and the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Display the result in the element with id="demo"

        cd = document.getElementsByClassName("countdown");
        //cdm = document.getElementsByClassName("cdmessage");


        for (var i=0; i<cd.length; i++){
            cd[i].innerHTML = "<div><strong>" + days + "</strong><i>Tage</i></div><div><strong>" + hours + "</strong><i>Stunden</i></div><div><strong>"
                + minutes + "</strong><i>Minuten</i></div><div><strong>" + seconds + "</strong><i>Sekunden</i></div>";
        }

        // If the count down is finished, write some text
        if (distance < 0) {
            clearInterval(x);
            var cdm = document.getElementsByClassName("cdmessage");
            var message1 = cdm[0].innerHTML;
            var cd = document.getElementsByClassName("countdown");

            for (var j=0; j<cdm.length; j++){
                console.log('hier: '+message1);
                cd[j].innerHTML = message1;
            }
        }
    }, 1000);
}



//News-Einblender einfaden
$(document).ready(function () {

    setTimeout(function(){
        $(function() {
            $('.home-banner').addClass('expand');

        });
    },1000);

});