//import $ from "jquery";

import lightGallery from 'lightgallery';

// $(".lightgallery").lightGallery({
//     download : false
// });


lightGallery(document.getElementById('moregal'), {
    selector: '.lightgallery-item',
    download: false,
});

